var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-3/5 p-4 pr-8 flex flex-col"},[_c('div',{staticClass:"flex items-center mb-2 h-12"},[_c('div',{staticClass:"module-title self-center"},[_vm._v("SMU")]),_c('div',{staticClass:"flex ml-auto"},[_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"pill-gray h-full flex flex-col justify-center items-center",on:{"click":function($event){return _vm.toggleSlSelectedItemsAll()}}},[_c('span',{class:{ 'text-aux': _vm.all }},[_vm._v("ALL")])])]),_vm._l((_vm.legendColors),function(pill,k){return _c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"pill-gray",style:(_vm.getOpacity(pill, k)),on:{"click":function($event){return _vm.toggleSlSelectedItems(k)}}},[_vm._v(" "+_vm._s(pill.name)+" ")])])})],2)]),_c('div',{staticClass:"h-full overflow-hidden relative"},[(_vm.loading)?_c('div',{staticClass:"absolute bg-box-80 z-1flex items-center mb-40 h-full w-full top-0 rounded-lg left-0"},[_c('loader',{attrs:{"loading":_vm.loading}})],1):(!_vm.loading && _vm.smu && _vm.smu.length === 0)?_c('div',{staticClass:"h-full w-full flex flex-col justify-center items-center"},[_c('span',{staticClass:"module-title text-font-gray"},[_vm._v("No data in this section")])]):_vm._e(),_c('div',{staticClass:"scroll-gradient"}),_c('transition-group',{staticClass:"h-full overflow-y-auto pb-10",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.smuFiltered),function(el){return _c('div',{key:el.id +
          '' +
          _vm.removeCharacters(el.id_sl) +
          '' +
          _vm.removeCharacters(el.name),staticClass:"box mb-2 flex"},[_c('div',{staticClass:"w-1 rounded mr-2",style:('background-color:' + _vm.legendColors[el.id_sl].color + ';')}),_c('div',{staticClass:"flex flex-col w-full overflow-hidden"},[_c('div',{staticClass:"text-xl font-bold mb-4"},[_vm._v(_vm._s(el.name))]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-full flex flex-row justify-start items-center"},[_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("ANSR")]),_c('span',{staticClass:"text-lg font-semibold mr-auto"},[_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.ner,2))+"€")]),_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("vs Obj.")]),_c('span',{staticClass:"text-lg font-semibold mr-auto",class:{
                  'text-success': el.ner_vs_objective > 0,
                  'text-danger': el.ner_vs_objective <= 0,
                }},[(el.ner_vs_objective > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.ner_vs_objective,2))),(el.ner_vs_objective != 'TBD')?_c('span',[_vm._v("%")]):_vm._e()]),(el.TNR != 'N/A')?_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("TNR")]):_vm._e(),(el.TNR != 'N/A')?_c('span',{staticClass:"text-lg font-semibold",class:{
                  'text-success': el.TNR > 0,
                  'text-danger': el.TNR <= 0,
                }},[_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.TNR,2))+"€")]):_vm._e(),_c('span',{staticClass:"text-font-gray font-semibold ml-auto mr-2"},[_vm._v("Rate")]),_c('span',{staticClass:"text-lg font-semibold mr-auto",class:{
                  'text-success': el.average_rate > 0,
                  'text-danger': el.average_rate <= 0,
                }},[_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.average_rate,2))+"€/h")]),_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("Unbilled")]),_c('span',{staticClass:"text-lg font-semibold mr-auto",class:{
                  'text-success': el.unbilled >= 0,
                  'text-danger': el.unbilled < 0,
                }},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(el.unbilled,2))+"€")]),_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("PMC")]),_c('span',{staticClass:"text-lg font-semibold mr-auto",class:{
                  'text-success': el.pm_payment >= 0,
                  'text-danger': el.pm_payment < 0,
                }},[_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.pm_payment,0))+"d")]),_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("Margin")]),_c('span',{staticClass:"text-lg font-semibold mr-auto",class:{
                  'text-success': el.margin >= 0,
                  'text-danger': el.margin < 0,
                }},[_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.margin,2))+"%")]),_c('span',{staticClass:"text-font-gray font-semibold mr-2"},[_vm._v("vs Obj")]),_c('span',{staticClass:"text-lg font-semibold",class:{
                  'text-success': el.avg_sale >= 0,
                  'text-danger': el.avg_sale < 0,
                }},[(el.margin_vs_objective > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("reduceBigNumbers")(el.margin_vs_objective,2))),(el.margin_vs_objective != 'TBD')?_c('span',[_vm._v(" ptos.")]):_vm._e()])])])])])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }